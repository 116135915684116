<template>
  <div>
    <b-card>
      <div class="d-flex">
        <p class="my-auto mr-auto">
          <i :class="$route.meta.iconClass"></i>
          <span
            style="font-size: 22px; color: #7f7f7f"
            class="font-weight-light"
          >
            Fiche capacité SI :
          </span>
          <span style="font-size: 25px" class="font-weight-bold">{{
            CAPACITE_SI.name
          }}</span>
        </p>

        <div class="row mb-3">
          <div class="col-12">
            <modal-actions
              @close="
                $router.go(
                  $store.getters['routerHistory/fromCreation'] ? -2 : -1
                )
              "
            />
          </div>
        </div>
      </div>
      <div class="shdow">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <p
            class="h3 text-secondary my-auto mr-auto"
            style="margin: 2.531px 0 !important"
          >
            Informations générales
          </p>
          <a
            v-if="(isAdmin || isSuper || isArchitect) && !editMode"
            class="text-secondary"
            style="font-size: 30px; color: #007bff !important"
            @click.prevent="editMode = !editMode"
            ><i class="mdi mdi-file-document-edit"></i
          ></a>
          <div
            v-else-if="isAdmin || isSuper || isArchitect"
            class="m-0 d-flex p-0"
          >
            <b-button
              style="min-width: 120px"
              type="submit"
              variant="secondary"
              pill
              @click="updateItem"
              :disabled="submitingForm"
            >
              <b-spinner
                v-if="submitingForm"
                small
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
            <b-button
              @click="editMode = !editMode"
              pill
              style="background-color: #adb5bd; border-color: #adb5bd"
              >Annuler</b-button
            >
          </div>

          <div class="col-12 p-0">
            <hr class="bg-secondary mb-3 mt-1" />
          </div>
        </div>

        <div class="row" v-if="editMode">
          <!-- First Col -->
          <div class="col-12 col-md-6">
            <b-form-group
              label-cols-sm="4"
              label-align-sm="left"
              label="Libellé:"
              label-class="font-weight-bold"
            >
              <b-form-input
                v-model="newCapacite.name"
                :class="{
                  'is-invalid':
                    $v.newCapacite.name.$error && $v.newCapacite.name.$dirty,
                }"
                type="text"
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newCapacite.name.$dirty"
              >
                {{
                  !$v.newCapacite.name.required
                    ? "Champ obligatoire"
                    : !$v.newCapacite.name.minLength
                    ? `Le champ doit contenir au moins ${$v.newCapacite.name.$params.minLength.min} caractères.`
                    : ""
                }}
              </span>
              <!--  -->
            </b-form-group>
            <b-form-group
              label-cols-sm="4"
              label-align-sm="left"
              label="Description:"
              label-class="font-weight-bold"
            >
              <b-form-input
                v-model="newCapacite.description"
                type="text"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols-sm="4"
              label-align-sm="left"
              label="Capacité de rattachement:"
              label-class="font-weight-bold"
            >
              <v-select
                label="text"
                :reduce="(capacite) => capacite.value"
                v-model="newCapacite.parent"
                :options="capaciteRatechements"
                placeholder="Capacité de rattachement"
                @input="
                  () => {
                    newCapacite.niveau = newCapacite.parent ? 2 : 1;
                  }
                "
              ></v-select>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group
              label-cols-sm="4"
              label-align-sm="left"
              label="Niveau:"
              label-class="font-weight-bold"
            >
              <b-form-input
                :class="{
                  'is-invalid':
                    $v.newCapacite.niveau.$error &&
                    $v.newCapacite.niveau.$dirty,
                }"
                v-model="newCapacite.niveau"
                type="number"
                disabled
                placeholder="Niveau"
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newCapacite.niveau.$dirty"
              >
                {{ !$v.newCapacite.niveau.required ? "Champ obligatoire" : "" }}
              </span>
            </b-form-group>
          </div>
        </div>

        <div class="row" v-else>
          <div class="col-12 col-md-6">
            <div class="my-4">
              <span>Libellé : </span>
              <span class="font-weight-bold mr-3">
                {{ CAPACITE_SI.name }}
              </span>
            </div>
            <div class="my-4">
              <span>Description : </span>
              <span class="font-weight-bold mr-3">
                {{ CAPACITE_SI.description }}
              </span>
            </div>
            <div class="my-4">
              <span>Capacité de rattachement : </span>
              <span v-if="CAPACITE_SI.parent" class="font-weight-bold mr-3">
                {{ CAPACITE_SI.parent.name }}
              </span>
              <span v-else> -</span>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="my-4">
              <span>Niveau : </span>
              <span class="font-weight-bold mr-3">
                {{ CAPACITE_SI.niveau || "-" }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div>
        <b-tabs class="tab-solid tab-solid-primary mt-3">
          <b-tab title="Applications rattachées"
            ><ApplicationsTable
              :applications="CAPACITE_SI.applications"
              :disabled="!(isAdmin || isSuper || isArchitect)"
          /></b-tab>
          <b-tab title="Cadres rattachés"
            ><CadresTable
              :disabled="!(isAdmin || isSuper || isArchitect)"
              :cadreEntreprises="CAPACITE_SI.cadreEntreprises" /></b-tab
        ></b-tabs>
      </div>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import { mapGetters } from "vuex";
import { required, minLength } from "vuelidate/lib/validators"; //validation
import Swal from "sweetalert2";
import ApplicationsTable from "./components/ApplicationsTable";
import CadresTable from "./components/CadresTable";
export default {
  components: {
    ModalActions,
    ApplicationsTable,
    CadresTable,
  },
  data: () => ({
    editMode: false,
    submitingForm: false,
    newCapacite: {
      name: "",
      description: "",
      sousCapacites: [],
      applications: [],
      niveau: "",
    },
  }),
  validations: {
    newCapacite: {
      name: {
        required,
        minLength: minLength(5),
      },

      niveau: {
        required,
      },
    },
  },
  methods: {
    updateItem() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$store
          .dispatch("capaciteSi/updateCapacite", {
            id: this.$route.params.id,
            ...this.newCapacite,
            niveau: parseInt(this.newCapacite.niveau),
            parent: this.newCapacite.parent,
            applications: this.newCapacite.applications.map(
              (application) => application["@id"]
            ),
            sousCapacites: this.newCapacite.sousCapacites.map(
              (capacite) => capacite["@id"]
            ),
          })
          .then(() => {
            this.$store
              .dispatch("capaciteSi/fetchCapacite", this.$route.params.id)
              .then(() => {
                Swal.fire("La capacité SI est mise à jour !", "", "success");
                this.editMode = false;
              });
          });
      }
    },
  },
  created() {
    this.$store
      .dispatch("capaciteSi/fetchCapacite", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("loader/toggleLoading", false);
      });
    this.$store.dispatch("capaciteSi/fetchAllCapacites");
  },
  computed: {
    ...mapGetters("capaciteSi", ["CAPACITE_SI", "CAPACITES_SI"]),
    ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),
    capaciteRatechements() {
      return this.CAPACITES_SI.filter(
        (capacite) =>
          capacite.id !== this.$route.params.id && capacite.niveau === 1
      ).map((capacite) => ({
        text: capacite.name,
        value: capacite["@id"],
      }));
    },
  },
  watch: {
    CAPACITE_SI: function () {
      this.newCapacite = {
        ...this.newCapacite,
        name: this.CAPACITE_SI.name,
        description: this.CAPACITE_SI.description,
        niveau: this.CAPACITE_SI.niveau || 1,
        sousCapacites: this.CAPACITE_SI.sousCapacites,
        applications: this.CAPACITE_SI.applications,
        parent: this.CAPACITE_SI.parent ? this.CAPACITE_SI.parent["@id"] : null,
      };
    },
  },
};
</script>

<style></style>
